import React,{useState,useEffect} from 'react';
import { useNavigate,useParams } from 'react-router-dom';


import Header from '../Header';
import Footer from '../Footer';
import Tourpagebackground from '../Tourpagebackground';


import featuredutk1 from '../../assets/Images/featuredutk1.svg';
import hotel from '../../assets/Images/hotel.svg';
import transport from '../../assets/Images/transport.svg';


function DomesticTourpage(){
    const params=useParams()

    const [domesticdata, setdomestictourpackage]=useState([])
    const [domesticheading,setdomesticheading]=useState('')

    useEffect(()=>{
        fetch('https://api.dutitrips.com/domesticToursPackages',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({TourId:params.TourId})
        })
        .then(res => res.json())
        .then(domesticdata=>{setdomestictourpackage(domesticdata)
            const headingText = `Top Locations In (${domesticdata[0].Package})`;
            setdomesticheading(headingText);
        })
        .catch(err=>console.log(err))
    },[]);
   
  

    const navigate=useNavigate();

    const handleViewDetailsClick=(PackageId,imageUrl)=>{
        navigate(`/DomesticTourdetails/${PackageId}`,{state:{imageUrl}})
    }
    return(
        <div>
            <div className='tour-page-main-container'>
                <div><Header/></div>
                <div className='tour-page-sub-tours-container'>
                    <h1 className='tour-page-sub-tours-heading'>{domesticheading}</h1>
                    <p className='tour-page-blue-line'></p>
                    
                <div className='tour-page-sub-tours-main-container'>
                    
                    {domesticdata.map((d,i)=>(
                        <div className='tour-page-sub-tour-each-container' key={i}>
                        <div className='tour-page-sub-tour-image-container'>
                            <img src={d.image_url} alt=''/>
                        </div>
                        <p className='tour-page-time-for-days'>{d.days}</p>
                        <h1 className='sub-tours-each-tour-heading'>{d.Package}</h1>
                        <div className='tour-page-small-background-conatiner'></div>

                        <div className='tour-page-stay-and-price-container'>
                                <div className='tour-page-stay-container'>
                                    <div className='tour-page-hotels-container'>
                                        <img src={hotel} alt=''/>
                                        <p>Hotels</p>
                                    </div>
                                    <div className='tour-page-transport-container'>
                                        <img src={transport} alt=''/>
                                        <p>Transfers</p>
                                    </div>
                                </div>

                                <div className='tour-page-price-container'>
                                    <p className='tour-page-feature-price'>{d.price}</p>
                                    <p className='tour-page-feature-person'>Per Person</p>
                                </div>
                            </div>

                            <hr className='tour-page-featured-tour-hr-line'/>
                            <p className='tour-page-featured-paragraph'>To Know more about the tour click on View details</p>

                            <div className='tour-page-contact-us-view-details-container'>
                                <a href={`https://wa.me/917287887749`} className='tour-page-feature-tour-contactus'>Contact Us</a>

                                <div  className='tour-page-feature-tour-view-details' onClick={()=>handleViewDetailsClick(d.package_id,d.image_url)}>View Details</div>
                            </div>
                        </div>
                    ))}



                </div>
                <div><Footer/></div>
                </div>
            </div>
        </div>
    );
}

export default DomesticTourpage;