import React, {useEffect,useState} from 'react';
import { useNavigate,useParams } from 'react-router-dom';
 
import './index.css';


import inttourimage from '../../assets/Images/inttourimage.svg'
import intcountryimage1 from '../../assets/Images/intcountryimage1.svg';

function InternationalTours() {

    const navigate=useNavigate();
   

    const handleIntCardClick=(TourId,tourName)=>{
        navigate(`/Tourpage/${TourId}`,{state:{tourName}})
    }

    const [intdata,setIntTour]=useState([])

    useEffect(()=>{
        fetch('https://api.dutitrips.com/internationalTours')
        .then(res=>res.json())
        .then(intdata=>setIntTour(intdata))
        .catch(err=>console.log(err))
    },[]);
  
    return(
        <div>
            <div className='international-tours-main-container'>
                <div className='international-tours-background'>
                    <div className='image-int-tour-container'>
                        <img src={inttourimage} alt=''/>
                    </div>
                    <div className='international-desinations-container'>
                        <div>
                        <h1 className='int-head'>International Destinations</h1>
                        <p className='blue-line-2'></p>
                        <p className='int-para'>From iconic landmarks to hidden gems, our international tours promise an enriching journey across cultures, ensuring you create lasting connections and explore the world in unparalleled style.</p>
                        </div>
                        <div className='international-destinations-countrys-container'>

                            {intdata.map((d,i)=>(
                                <div className='countries-container' key={i} onClick={()=>handleIntCardClick(d.tour_id,d.Name)}>
                                    <div className='countries-content-container'>
                                        <h1>{d.Name}</h1>
                                        <p>Planning your vacations ? our international holidays division are there to customise your holidays to any country in this world.</p>
                                    </div>
                                    <div className='countries-image'>
                                        <img src={d.image_url} alt=''/>
                                    </div>
                                    <p className='white-line'></p>
                                </div>
                            ))}

                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InternationalTours;