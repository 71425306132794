import React, {useState, useEffect} from 'react';
import Slider from "react-slick";

import Header from '../Header';
import Footer from '../Footer';



import gal1 from '../../assets/Images/gallery/tg1.jpg';
import gal2 from '../../assets/Images/gallery/tg2.jpg';
import gal3 from '../../assets/Images/gallery/tg3.jpg';
import gal4 from '../../assets/Images/gallery/tg4.jpg';
import gal5 from '../../assets/Images/gallery/tg5.jpg';
import gal6 from '../../assets/Images/gallery/tg6.jpg';
function TripGallery (){
    const [tripdata,settripdata]=useState([])


    useEffect(()=>{
        fetch('https://api.dutitrips.com/tripimages',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify()
        })
        .then(res => res.json())
        .then(tripdata=>{settripdata(tripdata)
        })
        .catch(err=>console.log(err))
    },[]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
      };

        return(
            <div>
                <div className='gallery-main-container'>
                    <div className='gallery-background-container'>
                        <div><Header/></div>
                        <div className='gallery-content-container'>
                            <h1 className='gallery-heading'>Gallery</h1>
                            <p className='gallery-content-para'>"Embark on a visual odyssey where every frame narrates tales of daring escapades. In this gallery of adventure, let courage be your guide, and each photo a portal to the extraordinary."</p>
                        </div>
                        <Slider {...settings} className="advanture-slide">
                            <div>
                            <img src={gal1} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal2} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal3} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal4} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal5} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal6} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                        </Slider>
                    </div>
                    <div className='our-gallery-main-container'>
                        <h1 className='our-gallery-heading'>Our Trip Gallery</h1>
                        <p className='our-gallery-blue-line'></p>
                        <p className='our-gallery-content'>Dive into a visual journey, where every image tells a story of exploration, friendship, and the incredible landscapes we've encountered on our trips.</p>
                        <div className='our-gallery-images-main-container'>

                            {tripdata.map((d,i)=>{
                                return(
                                    <div className='our-gallery-image-container'>
                                        <a href={d.trip_images}><img src={d.trip_images} alt='Image' className='gallery-image'/></a>
                                    </div>
                                )
                            })}
                        </div>

                        
                    </div>
                    <div><Footer/></div>
                </div>
            </div>
        );

}

export default TripGallery;