import React, {Component} from "react";
import {Link} from 'react-router-dom';


import payment from '../../assets/Images/payment-options.png'

class Advanturefooter extends Component{
    render(){
        return(
            <div>
                <a href="https://api.whatsapp.com/send?phone=917287887749&text=Welcome to Travel Duti" class="float" target="_blank">
                <i class="fa fa-whatsapp my-float"></i>
                </a>
                <div className="footer-main-container">
                    <div className="footer-background-container">
                        <div className="footer-container">
                            <h1>ABOUT US</h1>
                            <p>Welcome to Kranthi Adventure Academy, our team of certified adventure professionals will help you in fulfilling your adventure bucket list and can train you in becoming certified professionals.<br/> Fulfilling your dream with utmost safety is our priority.
                                
                            </p>

                        </div>
                        <p className="white-line-footer"></p>


                        <div className="footer-container">
                            <h1>USEFUL LINKS</h1>
                            <ul className="footer-list">
                                <p><li><Link to={'/'} className="footer-list-color">Home</Link></li></p>
                                <p><li><Link to={'/Aboutus/'} className="footer-list-color">About Us</Link></li></p>
                                <p><li><Link to={'/Advanture'} className="footer-list-color">Adventure</Link></li></p>
                                <p><li><Link to={'/Contactus'} className="footer-list-color">Contact Us</Link></li></p>
                            </ul>

                        </div>
                        <p className="white-line-footer"></p>

                        <div className="footer-container">
                            <h1>SOCIAL MEDIA LINKS</h1>
                            <ul className="footer-list">
                                <a href="#" class="fa fa-facebook"></a>
                                <a href="#" class="fa fa-twitter"></a>
                                <a href="#" class="fa fa-instagram"></a>
                                <p><li></li></p>
                                <p><li></li></p>
                                <p><li></li></p>
                            </ul>

                        </div>
                        <p className="white-line-footer"></p>

                        <div className="footer-container">
                            <h1>PAYMENT OPTION</h1>
                            <img src={payment} alt="" className="payment"/>
                        </div>
                        <p className="white-line-footer"></p>

                        <div className="footer-container">
                            <h1>CONTACT US</h1>
                            <p>India Office </p>
                            <p> 4th Floor, Xeno space, Capital Pk, Arunodaya Colony, Jaihind Enclave, Madhapur, Hyderabad, Telangana 500081</p>
                        </div>
                        <p className="white-line-footer"></p>
                    </div>
                    <hr/>
                    <p className="copy-right">© 2023 All Rights Reserved.</p>
                    <p className="company-name">Design and Developed by <a href="https://stepnext.com/">stepnext.com</a></p>
                </div>
            </div>
        );
    }
}

export default Advanturefooter;