import React, { useState, useEffect} from 'react';
import Slider from "react-slick";

import Header from '../Header';
import Footer from '../Footer';

import './index.css';


import adv1 from '../../assets/Images/gallery/videos/adv1.mp4';
import adv2 from '../../assets/Images/gallery/videos/adv2.mp4';
import adv3 from '../../assets/Images/gallery/videos/adv3.mp4';
import adv4 from '../../assets/Images/gallery/videos/adv4.mp4';
import adv5 from '../../assets/Images/gallery/videos/adv5.mp4';
import adv6 from '../../assets/Images/gallery/videos/adv6.mp4';
import adv7 from '../../assets/Images/gallery/videos/adv7.mp4';




import gal1 from '../../assets/Images/gallery/tg1.jpg';
import gal2 from '../../assets/Images/gallery/tg2.jpg';
import gal3 from '../../assets/Images/gallery/tg3.jpg';
import gal4 from '../../assets/Images/gallery/tg4.jpg';
import gal5 from '../../assets/Images/gallery/tg5.jpg';
import gal6 from '../../assets/Images/gallery/tg6.jpg';
function Gallery (){
    const [advanturedata,setadvanturedata]=useState([])


    useEffect(()=>{
        fetch('https://api.dutitrips.com/advantureimages',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify()
        })
        .then(res => res.json())
        .then(advanturedata=>{setadvanturedata(advanturedata)
        })
        .catch(err=>console.log(err))
    },[]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: false,
      };
        return(
            <div>
                <div className='gallery-main-container'>
                    <div className='gallery-background-container'>
                        <div><Header/></div>
                        <div className='gallery-content-container'>
                            <h1 className='gallery-heading'>Gallery</h1>
                            <p className='gallery-content-para'>"Embark on a visual odyssey where every frame narrates tales of daring escapades. In this gallery of adventure, let courage be your guide, and each photo a portal to the extraordinary."</p>
                        </div>
                        <Slider {...settings} className="advanture-slide">
                            <div>
                            <img src={gal1} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal2} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal3} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal4} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal5} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                            <div>
                            <img src={gal6} alt="kashmir" className="advanture-slide-image"/>
                            </div>
                        </Slider>
                    </div>
                    <div className='our-gallery-main-container'>
                        <h1 className='our-gallery-heading'>Our Gallery</h1>
                        <p className='our-gallery-blue-line'></p>
                        <p className='our-gallery-content'>"Journey into visual symphony – where each frame is a masterpiece, and emotion is captured in every hue."</p>
                        <div className='our-gallery-images-main-container'>

                            {advanturedata.map((d,i)=>{
                                return(
                                    <div className='our-gallery-image-container' key={i}>
                                        <a href={d.advanture_images}><img src={d.advanture_images} alt='sports&personalitydevelopment' className='gallery-image'/></a>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='space'></div>
                        <div className='our-gallery-videos-main-container'>
                            <div className='video-container'>
                                <video className='video' controls>
                                    <source src={adv2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className='small-videos-main-container'>
                                <video className='small-video' controls>
                                    <source src={adv1} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <video className='small-video' controls>
                                    <source src={adv3} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <video className='small-video' controls>
                                    <source src={adv4} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <video className='small-video' controls>
                                    <source src={adv5} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <video className='small-video' controls>
                                    <source src={adv6} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <video className='small-video' controls>
                                    <source src={adv7} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                    <div><Footer/></div>
                </div>
            </div>
        );
}

export default Gallery;