import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';



import himachalbackground from '../../assets/Images/himachalbackground.svg'


function Featuredtours(){
    const [domesticdata,setTour]=useState([])
    const [featureddata,setfeaturedTour]=useState([])


    useEffect(()=>{
        fetch('https://api.dutitrips.com/tours')
        .then(res=>res.json())
        .then(domesticdata=> {setTour(domesticdata)
        })
        .catch(err=> console.log(err))
    },[])


    useEffect(()=>{
        fetch('https://api.dutitrips.com/featuredtours')
        .then(res=>res.json())
        .then(featureddata=> {setfeaturedTour(featureddata)
        })
        .catch(err=> console.log(err))
    },[])


    const navigate = useNavigate();

    const handleCardClick = (TourId,tourName) => {
        navigate(`/DomesticTourpage/${TourId}`,{state:{tourName}});
    };
    
    return(
        <div>
            <div className='featured-tours-main-container'>
                <div className='featured-tours-content'>
                    <h1>Featured Tours</h1>
                    <p className='blue-line'></p>
                    <p className='featured-para-main'>Experience the charm of India with our featured tour that takes you from the enchanting houseboats of Kashmir to the adventure-packed hills of Manali and the vibrant shores of Goa, offering a perfect blend of nature, culture, and relaxation.
                    </p>
                </div>
                <div className='tour-cards-container'>
                    {domesticdata.map((d,i)=>{
                        return(
                        <div className='tour-card' key={i} onClick={()=>handleCardClick(d.Tour_id,d.Name)}>
                        <img src={d.ImageUrl} className='tour-card-image' alt=''/>
                        <div className='content-tour-card-container'>
                            <p className='content-tour-card'>{d.Name}</p>
                        </div>
                        <div className='days-container'>
                        </div>
                        <div className='background'></div>
                    </div>
                    )
                    })}
                </div>
            </div>


            <div className='himachal-pradesh-main-container'>
                <div className='himachal-pradesh-container'>
                    <img src={himachalbackground} alt='' className='himachal-background'/>
                    <div className='content-about-himachal-background'>
                        <h1>Discover Best of Our Tours</h1>
                        <p className='blue-line-himachal'></p>
                        <p className='content-paragraph'>Discover the allure of our best-curated tours, where the lush backwaters of Kerala, the majestic peaks of Himachal, the tranquil hills of Ooty, and the enchanting landscapes of Coorg await your exploration. Immerse yourself in the diverse beauty and rich experiences offered by these handpicked destinations, creating memories that last a lifetime.
                        </p>
                    </div>
                    <div className='himachal-cards-container'>
                        {featureddata.map((d,i)=>{
                            return(
                            <div className='himachal-card' key={i} onClick={()=>handleCardClick(d.Tour_id,d.Name)}>
                                <img src={d.ImageUrl} alt=''/>
                                <h1>{d.Name}</h1>
                                <p className='himachal-card-para'></p>
                            </div>
                            )
                        })}
                        
                        
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default Featuredtours