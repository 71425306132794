import React, {Component} from 'react';

import './index.css';

import Header from '../Header';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import contactusimage1 from '../../assets/Images/aboutimages/aboutusimage2.png';


class Contactus extends Component{
    render(){
        return(
            <div>
                <div className='contactus-main-container'>
                    <div className='contactus-background-image'>
                        <div><Header/></div>
                        <div className='contact-us-top-section'>
                            <h1 className='contact-us-heading-top'>Contact Us</h1>
                            <p className='contact-us-paragraph-top'>Your feedback matters! Contact us anytime for inquiries or support; our friendly team is ready to assist you promptly.</p>
                        </div>
                    </div>

                    <div className='contact-us-section'>
                        <h1 className='contact-us-heading'>Contact Us</h1>
                        <p className='contact-us-blue-line'></p>
                        <div className='contact-us-content-image-container'>
                            <div className='contact-us-content-container'>
                                <h1 className='contact-us-duti-heading'>Duti Trips</h1>
                                <div className='contact-us-address-container'>
                                    <h1 className='office-dest'>Head Office</h1>
                                    <h2>India Office</h2>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='icon'/>
                                        <p>4th Floor, Xeno space, Capital Pk, Arunodaya Colony, Jaihind Enclave, Madhapur, Hyderabad, Telangana 500081

                                        </p>
                                    </div>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faPhone} className='icon'/>
                                        <div>
                                            <h3>Phone No</h3>
                                            <p>7287887749</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='contact-us-address-container'>
                                    <h1>Branch Offices</h1>
                                    <h2>Himachal branch Office</h2>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='icon'/>
                                        <p>Opposite Circuit House Manali Hadimba Temple road Tehsil Manali, Distt Kullu Himachal Pradesh INDIA 175131
                                        </p>
                                    </div>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faPhone} className='icon'/>
                                        <div>
                                            <h3>Phone No</h3>
                                            <p>7287887749</p>
                                        </div>
                                    </div>
                                    
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faEnvelope}  className='icon'/>
                                        <div>
                                            <h3>Email Address</h3>
                                            <p>dutitrips@gmail.com</p>
                                        </div>
                                    </div>
                                    
                                </div>


                                

                                
                            </div>
                            <div className='contact-us-image-container'>
                                <img src={contactusimage1} alt=''/>
                                <div className='contact-us-flex-container'>
                                    <FontAwesomeIcon icon={faWhatsapp}  className='icon'/>
                                    <div>
                                        <h3>WhatsApp</h3>
                                        <p>7287887749</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='remaining-offices-brances'>
                                
                                <div className='contact-us-address-container width-class' >
                                    <h2>Kashmir Office</h2>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='icon'/>
                                        <p>NH1, SOUTH CITY SRINAGAR, NOWGAM KASHMIR 190015 Near law college
                                        </p>
                                    </div>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faPhone} className='icon'/>
                                        <div>
                                            <h3>Phone No</h3>
                                            <p>7287887749</p>
                                        </div>
                                    </div>
                                    
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faEnvelope}  className='icon'/>
                                        <div>
                                            <h3>Email Address</h3>
                                            <p>dutitrips@gmail.com</p>
                                        </div>
                                    </div>
                                    
                                </div>


                                <div className='contact-us-address-container width-class'>
                                    <h2>Andhra Pradesh branch Office</h2>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='icon'/>
                                        <p>Door no 1-1/2 MVP colony sector 1 MVP colony opposite netaji park ...vishakapattanam
                                        </p>
                                    </div>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faPhone} className='icon'/>
                                        <div>
                                            <h3>Phone No</h3>
                                            <p>7287887749</p>
                                        </div>
                                    </div>
                                    
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faEnvelope}  className='icon'/>
                                        <div>
                                            <h3>Email Address</h3>
                                            <p>dutitrips@gmail.com</p>
                                        </div>
                                    </div>
                                    
                                </div>


                                <div className='contact-us-address-container width-class'>
                                    <h2>Karnataka branch Office</h2>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='icon'/>
                                        <p>131/6 5th block  opp state bank of India ullalu main road sir m vishweshwar Bangalore 560056
                                        </p>
                                    </div>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faPhone} className='icon'/>
                                        <div>
                                            <h3>Phone No</h3>
                                            <p>7287887749</p>
                                        </div>
                                    </div>
                                    
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faEnvelope}  className='icon'/>
                                        <div>
                                            <h3>Email Address</h3>
                                            <p>dutitrips@gmail.com</p>
                                        </div>
                                    </div>
                                    
                                </div>



                                <div className='contact-us-address-container width-class'>
                                    <h2>Vietnam Office</h2>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='icon'/>
                                        <p>CT, Gelexia Riverside 885 - Tam Trinh, Hang Mai Hanoi, Vietnam
                                        </p>
                                    </div>
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faPhone} className='icon'/>
                                        <div>
                                            <h3>Phone No</h3>
                                            <p>7287887749</p>
                                        </div>
                                    </div>
                                    
                                    <div className='contact-us-flex-container'>
                                        <FontAwesomeIcon icon={faEnvelope}  className='icon'/>
                                        <div>
                                            <h3>Email Address</h3>
                                            <p>dutitrips@gmail.com</p>
                                        </div>
                                    </div>
                                    
                                </div>
                        </div>
                    </div>


                    <div className='contact-us-map-container'>
                        
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7612.690990400393!2d78.38312978693521!3d17.44317016932483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915fd82040fb%3A0x2f3d9ef3ddbd0520!2sAstralprise!5e0!3m2!1sen!2sin!4v1706510814255!5m2!1sen!2sin"
                            style={{ border: '0',marginLeft:'5vw' }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>


                    <div><Footer/></div>
                </div>
            </div>
        );
    }
}

export default Contactus;