import React, {Component} from 'react';

import './index.css';
import Header from '../Header';
import Footer from '../Footer';

import aboutusimage1 from '../../assets/Images/aboutimages/aboutusimage3.jpg';
import aboutusimage2 from '../../assets/Images/aboutimages/aboutusimage2.png';
import star1 from '../../assets/Images/star1.svg';
import star2 from '../../assets/Images/star2.svg';

class Aboutus extends Component{
    render(){
        return(
            <div>
                <div className='about-us-main-container'>
                    <div><Header/></div>
                    <div className='about-us-background-container'>
                        <div className='about-us-content-container'>
                            <h1>About Us</h1>
                            <h1>We Provide Different Type of tours </h1>
                            <p>Embark on unforgettable journeys with our tour company, offering meticulously curated domestic and international tours, creating enriching experiences and timeless memories for every traveler. Discover the world with confidence and comfort as we tailor exceptional travel adventures to suit diverse preferences.
                            </p>
                        </div>
                    </div>
                    <h1 className='about-us-heading'>About Us</h1>
                    <p className='about-us-blue-line'></p>

                    <div className='about-us-content-image-container1'>
                        <div className='about-us-image-container1'>
                            <img src={aboutusimage1} alt=''/>
                        </div>

                        <div className='about-us-content-container1'>
                            <h1>Get 100% Satisfaction With Our Service</h1>
                            <p>Welcome to TravelDuti and our team of dedicated travel professionals. As a company, we are progressively establishing 
                                ourselves as the foremost destination management company in India, with aspirations to lead in destination management
                                 across other Asian countries. At TravelDuti, we consider all our clients, both current and future, as partners. 
                                 We are committed to delivering a world-class travel experience, whether it's for individual travel, group travel, 
                                 incentive travel, or exploring cruise lines worldwide. Our customized programs are designed to cater to your specific 
                                 needs, ensuring complete satisfaction during your holidays. We anticipate that our valued customers will choose TravelDuti 
                                 repeatedly, and we trust they will recommend our services to their friends and colleagues.
                            </p>
                            <p>At TravelDuti, our commitment is unwavering as we strive to seamlessly deliver travel services to our customers every day of 
                                the year. We uphold the highest standards and prioritize the safety of our customers, consistently surpassing their expectations
                                 with the services we provide.
                            </p>
                        </div>
                    </div>


                    <div className='about-us-content-image-container2'>
                        <div className='about-us-content-container2'>
                            <div className='about-us-content-mini-container1'>
                                <h1>12 +</h1>
                                <h2>Year Of Experience</h2>
                                <p>"TravelDuti: Crafting Memorable Journeys for 12+ Years."
                                </p>
                            </div>

                            <div className='about-us-content-mini-container2'>
                                <h1>10,000 +</h1>
                                <h2> Customers</h2>
                                <p>"TravelDuti: Served Over 5000 Happy Customers and Counting."
                                </p>
                            </div>

                            <div className='about-us-content-mini-container3'>
                                <h1>30 +</h1>
                                <h2> Activities</h2>
                                <p>"Explore Limitless Adventures: 1200+ Exciting Activities with TravelDuti."
                                </p>
                            </div>


                            <div className='about-us-content-mini-container4'>
                                <h1>40 +</h1>
                                <h2>Destinations</h2>
                                <p>"Discover the World: 40+ Destinations Awaits You with TravelDuti."
                                </p>
                            </div>
                        </div>
                        
                        <div className='about-us-image-container2'>
                            <img src={aboutusimage2} alt=''/>
                        </div>
                    </div>


                    <div className='client-reviews-main-container'>
                        <div className='client-reviews-background-container'>
                            <div className='client-reviews-background-content'>
                                <h2>Client Reviews</h2>
                                <h1>TRAVELLER’S TESTIMONIAL</h1>
                                
                            </div>
                        </div>
                        <div className='review-container-flex'>
                            <div className='review-container'>
                                <div className='review-name-profile-container'>
                                    <div className='review-name-stars-container'>
                                        <h1>Samarth </h1>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                    </div>
                                    <div className='review-profile-container'></div>
                                </div>
                                <div className='review-content-paragraph'>
                                        <p>Our July 2022 trip to Krabi and Phuket with Travel Duti was fantastic. Virender sir did an outstanding job, ensuring a perfect mix of excursions and leisure. The experience was both relaxing and exhilarating, creating lasting memories.
                                        </p>
                                </div>
                            </div>


                            <div className='review-container'>
                            <div className='review-name-profile-container'>
                                    <div className='review-name-stars-container'>
                                        <h1>Praveen</h1>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star2} alt=''/>
                                    </div>
                                    <div className='review-profile-container'></div>
                                </div>
                                <div className='review-content-paragraph'>
                                        <p>We're extremely satisfied with Travel Duti's service for our Kullu Manali honeymoon package. The cab service was excellent, the driver experienced and friendly, and the hotel provided a pleasant stay with delicious food and humble staff. Thanks to Travel Duti for a great experience. 
                                        </p>
                                </div>
                            </div>


                            <div className='review-container'>
                            <div className='review-name-profile-container'>
                                    <div className='review-name-stars-container'>
                                        <h1>Aparna</h1>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                    </div>
                                    <div className='review-profile-container'></div>
                                </div>
                                <div className='review-content-paragraph'>
                                        <p>Thank you, Travel Duti, for arranging our Manali tour. The suggested program was excellent, and we had a great time in Lahaul. 
                                        </p>
                                </div>
                            </div>


                            <div className='review-container'>
                            <div className='review-name-profile-container'>
                                    <div className='review-name-stars-container'>
                                        <h1>Manasa</h1>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                        <img src={star1} alt=''/>
                                    </div>
                                    <div className='review-profile-container'></div>
                                </div>
                                <div className='review-content-paragraph'>
                                        <p>Thanks, Travel Duti, for organizing our Spiti tour package. It was amazing! The crafted tour and choice of homestays were incredible. 
                                        </p>
                                </div>
                            </div>



                        </div>
                        <div><Footer/></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Aboutus;