import React,{useState,useEffect} from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './index.css';

import Header from '../Header';
import Footer from '../Footer';
import Tourpagebackground from '../Tourpagebackground';


import featuredutk1 from '../../assets/Images/featuredutk1.svg';
import hotel from '../../assets/Images/hotel.svg';
import transport from '../../assets/Images/transport.svg';


function Tourpage(){
    const params=useParams()
    const location = useLocation();
    const tourName = location.state && location.state.tourName;

    const [intdata, setInttourpackage]=useState([])
    const [intheading,setIntheading]=useState('')

    useEffect(()=>{
        fetch('https://api.dutitrips.com/internationalToursPackages',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({TourId:params.TourId})
        })
        .then(res => res.json())
        .then(intdata=>{setInttourpackage(intdata)
            const headingText = `Top Locations In (${intdata[0].Package})`;
            setIntheading(headingText);
        })
        .catch(err=>console.log(err))
    },[]);
   
  

    const navigate=useNavigate();

    const handleViewDetailsClick=(PackageId,imageUrl)=>{
        navigate(`/Tourdetails/${PackageId}`,{state:{imageUrl}})
    }

    
    return(
        <div>
            <div className='tour-page-main-container'>
                <div><Header/></div>
                <div className='tour-page-background-image-container'>
                    {/* <div className='tour-page-content-container'>
                        <h1>{'Top Locations in '+tourName}</h1>
                        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                    </div> */}

                    {/* <div className='tour-page-explore-page-form-container'>
                        <form>
                            <h1 className='form-head'>Customize Your Tour</h1>
                            <hr style={{width:'80%',border:'none',height:'0.1vw',backgroundColor:'rgba(0, 0, 0, 1)'}}/>

                            <div className='tour-page-explore-page-form-inputs'>
                                <input placeholder='Your Name'/><br/>
                                <input placeholder='Email'/><br/>
                                <input placeholder='Phone Number'/><br/>
                                <div>
                                    <label>Travel Date</label><br/>
                                    <input placeholder='DD-MM-YY'/><br/>
                                </div>
                                <div>
                                    <label>Duration</label><br/>
                                    <input/><br/>
                                </div>
                                <div>
                                    <label>Adult</label><br/>
                                    <input/><br/>
                                </div>
                                <div>
                                    <label>Other Tour Requirements</label><br/>
                                    <input/><br/>
                                </div>
                            </div>

                            <div className='tour-page-explore-page-submit-button-container'>
                                <button>Submit</button>
                            </div>
                        </form>
                        </div> */}
                </div>
                <div className='tour-page-sub-tours-container'>
                    <h1 className='tour-page-sub-tours-heading'>{intheading}</h1>
                    <p className='tour-page-blue-line'></p>
                    
                <div className='tour-page-sub-tours-main-container'>
                    
                    {intdata.map((d,i)=>{
                        return(
                        <div className='tour-page-sub-tour-each-container' key={i}>
                        <div className='tour-page-sub-tour-image-container'>
                            <img src={d.image_url} alt=''/>
                        </div>
                        <p className='tour-page-time-for-days'>{d.days}</p>
                        <h1 className='sub-tours-each-tour-heading'>{d.Package}</h1>
                        <div className='tour-page-small-background-conatiner'></div>

                        <div className='tour-page-stay-and-price-container'>
                                <div className='tour-page-stay-container'>
                                    <div className='tour-page-hotels-container'>
                                        <img src={hotel} alt=''/>
                                        <p>Hotels</p>
                                    </div>
                                    <div className='tour-page-transport-container'>
                                        <img src={transport} alt=''/>
                                        <p>Transfers</p>
                                    </div>
                                </div>

                                <div className='tour-page-price-container'>
                                    <p className='tour-page-feature-price'>{d.price}</p>
                                    <p className='tour-page-feature-person'>Per Person</p>
                                </div>
                            </div>

                            <hr className='tour-page-featured-tour-hr-line'/>
                            <p className='tour-page-featured-paragraph'>To Know more about the tour click on View details</p>

                            <div className='tour-page-contact-us-view-details-container'>
                            <a href={`https://wa.me/917287887749`} className='tour-page-feature-tour-contactus'>Contact Us</a>

                                <div className='tour-page-feature-tour-view-details' onClick={()=>handleViewDetailsClick(d.Package_id,d.image_url)}>View Details</div>
                            </div>
                        </div>
                        )
                        })}



                </div>
                <div><Footer/></div>
                </div>
            </div>
        </div>
    );
}

export default Tourpage