import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './index.css';



import {FaAngleDown} from 'react-icons/fa';


import logofinal from '../../assets/Images/logofinalpng.png';

class Header extends Component {
  state = {
    isHolidayDropdownOpen: false,
    isAdventureDropdownOpen: false,
    isGalleryDropdownOpen:false,
    isMobileNavVisible: false,
    activeMobileDropdown: null,
  };

  handleMobileNavToggle = () => {
    this.setState((prevState) => ({
      isMobileNavVisible: !prevState.isMobileNavVisible,
      activeMobileDropdown: null,
    }));
  };

  handleMobileDropdownToggle = (dropdown) => {
    this.setState((prevState) => ({
      activeMobileDropdown: prevState.activeMobileDropdown === dropdown ? null : dropdown,
    }));
  };
  handleHolidayDropdownToggle = () => {
    this.setState((prevState) => ({
      isHolidayDropdownOpen: !prevState.isHolidayDropdownOpen,
    }));
  };

  handleAdventureDropdownToggle = () => {
    this.setState((prevState) => ({
      isAdventureDropdownOpen: !prevState.isAdventureDropdownOpen,
    }));
  };
  handleGallerydropdowntoggle=()=>{
    this.setState((prevState)=>({
      isGalleryDropdownOpen:!prevState.isGalleryDropdownOpen,
    }))
  }

  render() {
    const { activeMobileDropdown } = this.state;
    return (
      <div>
        <div className='header-main-container'>
          <div className='logo-container'>
            <img src={logofinal} alt=''/>
          </div>
          <div className='navbar-container'>
            <ul className='list-items'>
              <p>
                <li><Link to={'/'} className='advanture-link'>Home</Link></li>
              </p>
              <p>
                <li><Link to={`/Aboutus`} className='advanture-link'>About Us</Link></li>
              </p>

              <p
                onMouseEnter={this.handleHolidayDropdownToggle}
                onMouseLeave={this.handleHolidayDropdownToggle}
                
              >
                <li>Holiday Packages{' '}
                <FaAngleDown className='dropdown-span'/></li>

                {this.state.isHolidayDropdownOpen && (
                  <ul className='dropdown-content'>
                    <p>
                      <li><Link to={'/Holidaypackage/1'} className='advanture-link'>Honeymoon</Link></li>
                    </p>
                    <p>
                    <li><Link to={'/Holidaypackage/2'} className='advanture-link'>Solo</Link></li>
                    </p>
                    <p>
                      <li><Link to={'/Holidaypackage/5'} className='advanture-link'>Group</Link></li>
                    </p>
                    <p>
                      <li><Link to={'/Holidaypackage/3'} className='advanture-link'>Family</Link></li>
                    </p>
                    <p>
                      <li><Link to={'/Holidaypackage/4'} className='advanture-link'>Spiritual</Link></li>
                    </p>
                  </ul>
                )}
              </p>

              <p
                onMouseEnter={this.handleAdventureDropdownToggle}
                onMouseLeave={this.handleAdventureDropdownToggle}
                
              >
                <li><Link to={`/Adventure/`} className='advanture-link'>Adventures{' '}
                <FaAngleDown  className='dropdown-span'/></Link></li>
                

                {this.state.isAdventureDropdownOpen && (
                  <ul className='dropdown-content'>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>ROCKCLIMBING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>RAPELLING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>MOUNTAINEERING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>TREKKING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>SHOOTING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>ARCHERY{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>ZIPLINE{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>CAMPING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>NATURE AWARENESS{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>BIKE RIDING{' '}</Link></li></p>
                    <p><li><Link to={`/Adventure/`} className='advanture-link'>PARASAILING{' '}</Link></li></p>
                  </ul>
                )}
              </p>
              

              <p
                onMouseEnter={this.handleGallerydropdowntoggle}
                onMouseLeave={this.handleGallerydropdowntoggle}
                
              ><li>
                Gallery{' '}
                <FaAngleDown className='dropdown-span'/></li>

                {this.state.isGalleryDropdownOpen && (
                  <ul className='dropdown-content'>
                    <div className='gallery-dropdown'>
                      <p>
                          <li><Link to={'/TripGallery/'} className='advanture-link'>Trip Gallery</Link></li>
                        </p> 
                        <p>
                        <li><Link to={`/Gallery/`} className='advanture-link'>Advanture<br/> Gallery</Link></li>
                        </p>
                        
                    </div>                  
                  </ul>
                )}
              </p>
              {/* <li><Link to={`/Awards/`} className='advanture-link'>Awards</Link></li> */}
              <p>
                <li><Link to={`/Contactus/`} className='advanture-link'>Contact Us</Link></li>
              </p>
            </ul>
          </div>
          <div className='navbar-mobile' onClick={this.handleMobileNavToggle}>
            <p className='nav-icon'></p>
            <p className='nav-icon'></p>
            <p className='nav-icon'></p>
          </div>
          {this.state.isMobileNavVisible && (
          <div className='mobile-nav-links'>
            <ul>
            <p href='Home'>
                <li><Link to={'/'} className='advanture-link'>Home</Link></li>
              </p>
              <p>
                <li><Link to={`/Aboutus/`} className='advanture-link'>About Us</Link></li>
              </p>
              <p onClick={() => this.handleMobileDropdownToggle('holiday')}>
                Holiday Package
                {activeMobileDropdown === 'holiday' && (
                  <ul className='sub-links'>
                    <p>
                      <li><Link to={'/Holidaypackage/1'} className='advanture-link'>Honeymoon</Link></li>
                    </p>
                    <p>
                    <li><Link to={'/Holidaypackage/2'} className='advanture-link'>Solo</Link></li>
                    </p>
                    <p>
                      <li><Link to={'/Holidaypackage/5'} className='advanture-link'>Group</Link></li>
                    </p>
                    <p>
                      <li><Link to={'/Holidaypackage/3'} className='advanture-link'>Family</Link></li>
                    </p>
                    <p>
                      <li><Link to={'/Holidaypackage/4'} className='advanture-link'>Spiritual</Link></li>
                    </p>
                  </ul>
                )}
              </p>
              <p><li><Link to={`/Adventure/`} className='advanture-link'>Adventures{' '}</Link></li></p>
              <li onClick={() => this.handleMobileDropdownToggle('gallery')}>
                Gallery
                {activeMobileDropdown === 'gallery' && (
                  <ul className='sub-links'>
                    <p>
                          <li><Link to={'/TripGallery/'} className='advanture-link'>Trip Gallery</Link></li>
                        </p> 
                        <p>
                        <li><Link to={`/Gallery/`} className='advanture-link'>Advanture Gallery</Link></li>
                        </p>
                  </ul>
                )}
                </li>
                <p>
                <li><Link to={`/Contactus/`} className='advanture-link'>Contact Us</Link></li>
              </p>
            </ul>
          </div>
        )}
        </div>
      </div>
    );
  }
}

export default Header;