import React, { Component } from "react";
import Slider from "react-slick";

import './index.css';



import advslide1 from '../../assets/Images/adventureimages/advslide11.jpg';
import advslide2 from '../../assets/Images/adventureimages/advslide22.jpg';
import advslide3 from '../../assets/Images/adventureimages/advslide33.jpg';
import advslide4 from '../../assets/Images/adventureimages/advslide44.jpg';
import advslide5 from '../../assets/Images/adventureimages/advslide55.jpg';
import advslide6 from '../../assets/Images/adventureimages/advslide66.jpg';

export default class Advantureslide extends Component {
  render() {
    const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
  };
  return (
    <div className="advanture-slide-content">
      <div className='advanture-page-content-container fixed-content'>
          <h1 className='advanture-slide-heading'>KRANTHI ADVENTURE ACADEMY®</h1>
          <p className='advanture-slide-paragraph'>Indulge in a thrilling spectrum of adventures with us, from the heights of parasailing and rock climbing to the exhilaration of ziplining and the precision of archery. Unleash your adventurous spirit and embrace an adrenaline-fueled escape with our diverse range of heart-pounding experiences.
          </p>
      </div>
      <Slider {...settings} className="advanture-slide">
        <div>
          <img src={advslide1} alt="kashmir" className="advanture-slide-image"/>
        </div>
        <div>
          <img src={advslide2} alt="kashmir" className="advanture-slide-image"/>
        </div>
        <div>
          <img src={advslide3} alt="kashmir" className="advanture-slide-image"/>
        </div>
        <div>
          <img src={advslide4} alt="kashmir" className="advanture-slide-image"/>
        </div>
        <div>
          <img src={advslide5} alt="kashmir" className="advanture-slide-image"/>
        </div>
        <div>
          <img src={advslide6} alt="kashmir" className="advanture-slide-image"/>
        </div>
      </Slider>
    </div>
  );
 }
}
