import React, { useState, useEffect, useMemo } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

const ImageSlider = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const importImages = async () => {
      const importedImages = await Promise.all([
        import('../../assets/Images/kashmirboat.png'),
        import('../../assets/Images/manalislider.png'),
        import('../../assets/Images/rajastanslider.png'),
        import('../../assets/Images/goaslider.png'),
        import('../../assets/Images/viatnamslider.png'),
        import('../../assets/Images/ootyslider.png'),
        import('../../assets/Images/coorgslider.png'),
        import('../../assets/Images/keralaslider.png'),
      ]);

      setImages(importedImages.map((module) => module.default));
    };

    importImages();
  }, []);

  const sliderContent = useMemo(
    () => [
      { id: 1, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Kashmir' },
      { id: 2, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Manali' },
      { id: 3, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Rajasthan' },
      { id: 4, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Goa' },
      { id: 5, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Vietnam' },
      { id: 6, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Ooty' },
      { id: 7, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Coorg' },
      { id: 8, title: 'Duti Trips', caption: 'Dream Upon & Travel Immense', slidebottom: 'WE DESIGN YOUR MEMORIES.', slidename: 'Kerala' },
    ],
    []
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: '0',
    pauseOnHover: false,
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index} className='image-slide-container'>
          <img src={image} alt={`Slide ${index + 1}`} className='image-slide' loading='lazy' />
          <div className='content-button-container'>
            <div className='slide-content'>
              <h2 className='slide-title'>{sliderContent[index].title}</h2>
              <p className='slider-caption'>{sliderContent[index].caption}</p>
            </div>
            <h1 className='slide-bottom'>{sliderContent[index].slidebottom}</h1>
            <h1 className='slide-name'>{sliderContent[index].slidename}</h1>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
