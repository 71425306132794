import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import contactusimage1 from '../../assets/Images/aboutimages/aboutusimage2.png';
import './index.css';
import Header from "../Header";
import dinner from '../../assets/Images/dinner.svg';
import Footer from "../Footer";

function Tourdetails() {
    const params = useParams();
    const [intdetails, setIntTourdetails] = useState([]);
    const [inttourdetailsheading, setInttourdetailsheading] = useState('');
    const location = useLocation();
    const imageUrl = location.state && location.state.imageUrl;

    useEffect(() => {
        fetch('https://api.dutitrips.com/internationalTourDetails', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ PackageId: params.PackageId })
        })
            .then(res => res.json())
            .then(intdetails => {
                setIntTourdetails(intdetails);
                const headingText = `${intdetails[0].Name}`;
                setInttourdetailsheading(headingText);
            })
            .catch(err => console.log(err))
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        
        try {
            const response = await fetch('https://api.dutitrips.com/domestictourdata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            console.log("ggg",response)
            if (response.ok) {
                console.log('Form data submitted successfully');
            } else {
                console.error('Failed to submit form data');
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <div>
            <div className="tour-details-main-container">
                <div><Header /></div>
                <div className="image-back-container">
                    {imageUrl && <img src={imageUrl} alt="Tour Image" className="image-back" />}
                </div>
                <div className='tour-page-background-image-container'>
                    <div className='tour-page-explore-page-form-container'>
                        <form onSubmit={handleSubmit}>
                            <h1 className='form-head'>Customize Your Tour</h1>
                            <hr style={{ width: '80%', border: 'none', height: '0.1vw', backgroundColor: 'rgba(0, 0, 0, 1)' }} />

                            <div className='tour-page-explore-page-form-inputs'>
                                <input name="name" placeholder='Your Name' /><br />
                                <input name="email" placeholder='Email' /><br />
                                <input name="phoneNumber" placeholder='Phone Number' /><br />
                                <input name="travelDate" placeholder='Travel Date' /><br />
                                <input name="duration" placeholder="Duration" /><br />
                                <input name="adult" placeholder="Adult" /><br />
                                <input name="tourRequirements" placeholder="Other Tour Requirements" /><br />
                            </div>

                            <div className='tour-page-explore-page-submit-button-container'>
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="tour-details-heading">
                    <h1>{inttourdetailsheading}</h1>
                    <p className="tour-details-blue-line"></p>
                </div>
                <h1 className="tour-details-sub-head">Itinerary Details</h1>
                <div className="tour-details-dinner-hotels-container-flex"></div>
                <div className="tour-details-days-container">
                    <h1 className="tour-details-days-heading">{'ITINERARY :- ' + inttourdetailsheading}</h1>
                    <div className="tour-details-days-total-container">
                        {intdetails.map((d, i) => (
                            <div className="tour-details-each-main-container">
                                <div className="tour-details-days-each-container">
                                    <p className="tour-details-blue-background">{'Day 0' + d.Days}</p>
                                    <div className="tour-details-content-icon-container">
                                        <p className="tour-details-para1-container">{'Day ' + d.Days + ' Activities'}</p>
                                        <div className="line-arrow-icon">
                                            <p className="tour-details-vertical-line"></p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <p className="tour-details-para2">{d.Content}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='contact-us-section'>
                    <h1 className='contact-us-heading'>Contact</h1>
                    <p className='contact-us-blue-line'></p>
                    <div className='contact-us-content-image-container'>
                        <div className='contact-us-content-container'>
                            <h1 className='contact-us-duti-heading'>Duti Trips</h1>
                            <div className='contact-us-address-container'>
                                <h1 className='office-dest'>Head Office</h1>
                                <h2>India Office</h2>
                                <div className='contact-us-flex-container'>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className='icon' />
                                    <p>4th Floor, Xeno space, Capital Pk, Arunodaya Colony, Jaihind Enclave, Madhapur, Hyderabad, Telangana 500081

                                    </p>
                                </div>
                                <div className='contact-us-flex-container'>
                                    <FontAwesomeIcon icon={faPhone} className='icon' />
                                    <div>
                                        <h3>Phone No</h3>
                                        <p>7287887749</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='contact-us-image-container'>
                            <img src={contactusimage1} alt='' />
                            <div className='contact-us-flex-container'>
                                <FontAwesomeIcon icon={faWhatsapp} className='icon' />
                                <div>
                                    <h3>WhatsApp</h3>
                                    <p>+91 7287887749</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div><Footer /></div>
            </div>
        </div>
    );
}

export default Tourdetails;
