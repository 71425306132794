import React, {Component} from "react";
import {Link} from 'react-router-dom';

import './index.css';

import payment from '../../assets/Images/payment-options.png'

class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showCookiesPopup: true,
            acceptCookies: false
        };
    }

    handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        this.setState({ showCookiesPopup: false });
    }

    handleCheckboxChange = (event) => {
        this.setState({ acceptCookies: event.target.checked });
    }
    render(){
        const { showCookiesPopup, acceptCookies } = this.state;
        return(
            <div>
                <div className="coockies-container">
                    {showCookiesPopup && (
                        <div id="cookies-popup" className="cookies-popup">
                            <p>We use cookies to enhance your experience on our website. By continuing to use this site, you consent to our use of cookies. <Link to="/privacy-policy">Learn more</Link></p>
                            <label>
                                <input type="checkbox" checked={acceptCookies} onChange={this.handleCheckboxChange} />
                                I accept the use of cookies
                            </label>
                            <button onClick={this.handleAcceptCookies} disabled={!acceptCookies}>Accept</button>
                        </div>
                    )}
                </div>
                <a href="https://api.whatsapp.com/send?phone=917287887749&text=Welcome to Travel Duti" class="float" target="_blank">
                <i class="fa fa-whatsapp my-float whats-icon"></i>
                </a>
                <div className="footer-main-container">
                    <div className="footer-background-container">
                        <div className="footer-container">
                            <h1>ABOUT US</h1>
                            <p>Welcome to TravelDuti, our team of dedicated travel professionals will help you to make your journey memorable. Our company is steadily emerging as premier destination management company in India, with aspirations to extend our leadership in destination management to other prominent Asian countries.
                                
                            </p>

                        </div>
                        <p className="white-line-footer"></p>


                        <div className="footer-container">
                            <h1>USEFUL LINKS</h1>
                            <ul className="footer-list">
                                <p><li><Link to={'/'} className="footer-list-color">Home</Link></li></p>
                                <p><li><Link to={'/Aboutus/'} className="footer-list-color">About Us</Link></li></p>
                                <p><li><Link to={'/Adventure'} className="footer-list-color">Adventure</Link></li></p>
                                <p><li><Link to={'/Contactus'} className="footer-list-color">Contact Us</Link></li></p>
                            </ul>

                        </div>
                        <p className="white-line-footer"></p>

                        <div className="footer-container">
                            <h1>SOCIAL MEDIA LINKS</h1>
                            <ul className="footer-list">
                                <a href="#" class="fa fa-facebook"></a>
                                <a href="#" class="fa fa-twitter"></a>
                                <a href="#" class="fa fa-instagram"></a>
                                <p><li></li></p>
                                <p><li></li></p>
                                <p><li></li></p>
                            </ul>

                        </div>
                        <p className="white-line-footer"></p>

                        <div className="footer-container">
                            <h1>PAYMENT OPTION</h1>
                            <img src={payment} alt="" className="payment"/>
                        </div>
                        <p className="white-line-footer"></p>

                        <div className="footer-container">
                            <h1>CONTACT US</h1>
                            <p>India Office </p>
                            <p> 4th Floor, Xeno space, Capital Pk, Arunodaya Colony, Jaihind Enclave, Madhapur, Hyderabad, Telangana 500081</p>
                        </div>
                        <p className="white-line-footer"></p>
                    </div>
                    <hr/>
                    <p className="copy-right">© 2024 All Rights Reserved.</p>
                    <p className="company-name">Design and Developed by <a href="https://stepnext.com/">stepnext.com</a></p>
                </div>
            </div>
        );
    }
}

export default Footer;