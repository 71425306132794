import React, {Component} from 'react';

import './index.css';

class Awards extends Component{
    render(){
        return(
            <div>
                <div className='awards-main-container'></div>
            </div>
        );
    }
}

export default Awards;