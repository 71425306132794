import React, {Component} from 'react';

import './index.css';
import Header from '../Header';
import Footer from '../Footer';
import rockclimbing from '../../assets/Images/adventureimages/rockclimbing11.jpg';
import rapelling from '../../assets/Images/adventureimages/rapelling1.jpg';
import trekking from '../../assets/Images/adventureimages/trekking1.jpg';
import mountaineering from '../../assets/Images/adventureimages/mountaineering11.jpg';
import natureawereness from '../../assets/Images/adventureimages/natureawereness1.jpg';
import bikeriding from '../../assets/Images/adventureimages/bikeriding.jpg'
import camping from '../../assets/Images/adventureimages/camping1.jpg'
import parasailing from '../../assets/Images/adventureimages/parasailing.jpg';
import zipline from '../../assets/Images/adventureimages/zipline.jpg';
import archery from '../../assets/Images/adventureimages/archery1.jpg';
import shooting from '../../assets/Images/adventureimages/shooting.jpg';


import aboutfounder from '../../assets/Images/adventureimages/aboutfounder.png';
import Advantureslide from '../Advantureslide';
import Advantureheader from '../Advantureheader';
import Advanturefooter from '../Advanturefooter';

class Advanture extends Component{
    render(){
        return(
            <div>
                <div className='advanture-main-container'>
                        <Advantureheader/>
                    <div><Advantureslide/></div>

                    <h1 className='about-founder-heading'>About Founder</h1>
                    <div className='about-founder-main-container'>
                        
                        <div className='about-founder-image-container'>
                            <img src={aboutfounder} alt=''/>
                            <h1 className='founder-name'>Kranthi Prasad</h1>
                            <h1 className='founder-head'>Founder and Managing Director of<br/> Kranthi Group of Companies</h1>
                        </div>
                        <div className='about-founder-content-container'>
                            <p><span className='bold'>Kranthi Prasad </span>has an impressive background with 15 years of experience as a martial arts trainer and expertise as a yoga teacher he trained so many financially poor children to fulfill their dreams.</p>
                            <p>Kranthi's dedication to training financially disadvantaged children, helping them achieve their dreams , and guiding numerous students to win gold medals is truly commendable. Additionally, organizing adventure camps across various locations in Karnataka, and Andhra Pradesh, Telangana & Tamilnadu.</p>
                            <p>Mr Kranthi Prasad was honoured with the prestigious <span>"Aryabhata International Award"</span> from the hands of former <span className='bold'>supreme court judge Mr.Shivaraj Patel </span>in the year of 2011 for his service in training underprevilized children from rural areas in Karnataka.<br/> Kranthi also recived several gold medals in Martial arts and Yoga.</p>
                        </div>
                    </div>



                    <div className='advanture-events-main-container'>
                        <div className='advanture-events-background-container'>
                            <div className='advanture-events-content-container'>
                                <h1 className='advanture-events-heading'>Adventure Events</h1>
                                <p className='blue-line-advanture-events'></p>
                                <p className='advanture-events-paragraph'>Embark on an extraordinary journey filled with adrenaline-fueled adventures. Scale towering cliffs with rock climbing, conquering nature's obstacles. Experience the exhilarating descent of rappelling, and take to the skies with parasailing, where the winds carry you across breathtaking landscapes. Our adventure events promise unforgettable moments of thrill and awe-inspiring exploration.</p>
                            </div>
                        </div>
                        <div className='advanture-events-programs-container'>
                            <div className='advanture-event-program'>
                                <img src={rockclimbing} alt=''/>
                                <h1 className='advanture-event-heading'>ROCK CLIMBING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>Rock climbing is a sport in which participants climb up, across, or down natural rock formations or indoor climbing walls. The goal is to reach the summit of a formation or the endpoint of a usually pre-defined route without falling. Rock climbing is a physically and mentally demanding sport, one that often tests a climber's strength, endurance, agility and balance along with mental control.</p>
                                    <a href='https://en.wikipedia.org/wiki/Rockclimbing' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>




                            <div className='advanture-event-program'>
                                <img src={rapelling} alt=''/>
                                <h1 className='advanture-event-heading'>RAPELLING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>The technique is used by climbers, mountaineers, cavers, canyoners, search and rescue and rope access technicians to descend cliffs or slopes when they are too steep and/or dangerous to descend without protection. Many climbers use this technique to protect established anchors from damage. Rope access technicians also use this as a method to access difficult-to-reach</p>
                                    <a href='https://en.wikipedia.org/wiki/Rapelling' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>




                            <div className='advanture-event-program'>
                                <img src={trekking} alt=''/>
                                <h1 className='advanture-event-heading'>TREKKING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>Backpacking is the outdoor recreation of carrying gear on one's back while hiking for more than a day. It is often an extended journey[1] and may involve camping outdoors. In North America, tenting is common, where simple shelters and mountain huts, widely found in Europe, are rare. In New Zealand, hiking is called tramping, and tents are used alongside a nationwide network of huts.</p>
                                    <a href='https://en.wikipedia.org/wiki/Trekking' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>




                            <div className='advanture-event-program'>
                                <img src={shooting} alt=''/>
                                <h1 className='advanture-event-heading'>SHOOTING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>Shooting is the act or process of discharging a projectile from a ranged weapon (such as a gun, bow, crossbow, slingshot, or blowpipe). Even the acts of launching flame, artillery, darts, harpoons, grenades, surgerys, and guided missiles can be considered acts of shooting. When using a firearm, the act of shooting is often called firing</p>
                                    <a href='https://en.wikipedia.org/wiki/Shooting' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>




                            <div className='advanture-event-program'>
                                <img src={archery} alt=''/>
                                <h1 className='advanture-event-heading'>ARCHERY</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>The oldest known evidence of the bow and arrow comes from South African sites such as Sibudu Cave, where the remains of bone and stone arrowheads have been found dating approximately 72,000 to 60,000 years ago.[4][5][6][7][8][9] Based on indirect evidence, the bow also seems to have appeared or reappeared later in Eurasia, near the transition from the</p>
                                    <a href='https://en.wikipedia.org/wiki/Archery' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>




                            <div className='advanture-event-program'>
                                <img src={zipline} alt=''/>
                                <h1 className='advanture-event-heading'>ZIP LINE</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>A zip-line, zip line, zip-wire, flying fox, or death slide[1][2][3][4][5][6] is a pulley suspended on a cable, usually made of stainless steel, mounted on a slope. It is designed to enable cargo or a person propelled by gravity to travel from the top to the bottom of the inclined cable by holding on to, or being attached to, the freely moving pulley. It has been described as essentially</p>
                                    <a href='https://en.wikipedia.org/wiki/Zipline' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>


                            <div className='advanture-event-program'>
                                <img src={camping} alt=''/>
                                <h1 className='advanture-event-heading'>CAMPING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>Camping is a form of outdoor recreation or outdoor education involving overnight stays with a basic temporary shelter such as a tent. Camping can also include a recreational vehicle, sheltered cabins, a permanent tent, a shelter such as a bivy or tarp, or no shelter at all. Typically, participants leave developed areas to spend time outdoors, in pursuit of activities providing them enjoyment or an educational experience. Spending the night away from home distinguishes camping from day-tripping, picnicking, and other outdoor activities.</p>
                                    <a href='https://en.wikipedia.org/wiki/Camping' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>




                            <div className='advanture-event-program'>
                                <img src={natureawereness} alt=''/>
                                <h1 className='advanture-event-heading'>NATURE AWARENESS</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                                    <a href='https://en.wikipedia.org/wiki/Nature' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>



                            <div className='advanture-event-program'>
                                <img src={mountaineering} alt=''/>
                                <h1 className='advanture-event-heading'>MOUNTAINEERING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>Mountaineering, mountain climbing, or alpinism[2] is a set of outdoor activities that involves ascending mountains. Mountaineering-related activities include traditional outdoor climbing, skiing, and traversing via ferratas that have become sports in their own right.[3][4][5][6] Indoor climbing, sport climbing, and bouldering are also considered variants of mountaineering</p>
                                    <a href='https://en.wikipedia.org/wiki/Mountaineering' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>

                            <div className='advanture-event-program'>
                                <img src={bikeriding} alt=''/>
                                <h1 className='advanture-event-heading'>BIKE RIDING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>"Bicycling" and "Biking" redirect here. For the magazine, see Bicycling (magazine). For the Frank Ocean song, see Biking (song).
                                        "Cyclist" redirects here. For the magazine, see Cyclist (magazine). For the 1987 Iranian film, see The Cyclist.
                                        For the hockey strategy, see Cycling (ice hockey).</p>
                                    <a href='https://en.wikipedia.org/wiki/Bikeriding' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>

                            <div className='advanture-event-program'>
                                <img src={parasailing} alt=''/>
                                <h1 className='advanture-event-heading'>PARASAILING</h1>
                                <div className='advanture-event-para-more-container'>
                                    <p className='advanture-event-paragraph'>Parasailing, also known as parascending, paraskiing or parakiting, is a recreational kiting activity where a person is towed behind a vehicle while attached to a specially designed canopy wing that resembles a parachute, known as a parasail wing. The manned kite's moving anchor may be a car, truck, or boat. The harness attaches the occupant to the parasail,</p>
                                    <a href='https://en.wikipedia.org/wiki/Parasailing' className='advanture-event-more-info' target='_blank' rel='noopener noreferrer'>MORE INFO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Advanturefooter/>
                    
                </div>
            </div>
        );
    }
}

export default Advanture;