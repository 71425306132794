import React from 'react';


import Adventure from './components/Advanture';
import Aboutus from './components/Aboutus';
import Contactus from './components/Contactus';
import Gallery from './components/Gallery';
import Awards from './components/Awards';
import MainPage from './Mainpage';
import Tourpage from './components/Tourpage';
import Tourdetails from './components/Tourdetails';
import Holidaypackage from './components/Holidaypackage';
import Advantureslide from './components/Advantureslide';
import './App.css';
import DomesticTourpage from './components/DomesticTourpage';
import DomesticTourdetails from './components/DomesticTourdetails';
import TripGallery from './components/TripGallery';
import Featuredtours from './components/Featuredtours';
import Advantureheader from './components/Advantureheader';
import Advanturefooter from './components/Advanturefooter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <Router >
       <Routes>
        
          <Route path="" element={<MainPage />}/>
          <Route path="Adventure" element={<Adventure />} />
          <Route path="Aboutus" element={<Aboutus />} />
          <Route path="Contactus" element={<Contactus />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="Awards" element={<Awards />} />
          <Route path="Tourpage/:TourId" element={<Tourpage />} />
          <Route path="Tourdetails/:PackageId" element={<Tourdetails />} />
          <Route path="Holidaypackage/:Id" element={<Holidaypackage />} />
          <Route path="Advantureslide/" element={<Advantureslide />} />
          <Route path="DomesticTourpage/:TourId" element={<DomesticTourpage />} />
          <Route path="DomesticTourdetails/:PackageId" element={<DomesticTourdetails />} />
          <Route path="TripGallery" element={<TripGallery />} />
          <Route path="FeaturedTours/:tourName" element={<Featuredtours />} />
          <Route path='Advantureheader/' element={<Advantureheader/>}/>
          <Route path='Advanturefooter/' element={<Advanturefooter/>}/>
        
      </Routes>
    </Router>
  );
}

export default App;
