import React from 'react';
import { useParams } from 'react-router-dom';

import './index.css';
import Header from '../Header';
import Footer from '../Footer';

import honeymoon from '../../assets/Images/holidayimages/honeymoon.jpg';
import spiritual from '../../assets/Images/holidayimages/spiritual.jpg';
import family from '../../assets/Images/holidayimages/family.jpg';
import solo from '../../assets/Images/holidayimages/solo.jpg';
import group from '../../assets/Images/holidayimages/group1.jpg';


import honeymoon2 from '../../assets/Images/holidayimages/honeymoon21.jpg';
import spiritual2 from '../../assets/Images/holidayimages/spiritual21.jpg';
import family2 from '../../assets/Images/holidayimages/family21.jpg';
import solo2 from '../../assets/Images/holidayimages/solo2.jpg';
import group2 from '../../assets/Images/holidayimages/group21.jpg';



const holidayPackageContent = [
  { Holidayid: 1, Title: 'Honeymoon', Head:'Witness the Best Tours With Our Travel Duti',Content: 'Kashmir, Manali, Ooty, Shimla Honeymoon Tour Packages: Our Tours, an enchanting destination, is a cherished haven for honeymooners. Nestled amidst lush green forests, apple orchards, and pristine air, it offers a perfect retreat with beautiful huts and serene landscapes. Indulge in the romance of our Tours with our customized honeymoon packages, available from various cities across India. Experience the magic of love amid the captivating beauty of Our Tours.',Content2:'Kerala, Vietnam, Bali Honeymoon Tour Packages: Elevate your honeymoon experience with our exclusive honeymoon tours, meticulously crafted for unforgettable moments. At TravelDuti, we transform your dreams into reality with exciting offers and a diverse range of packages. Immerse yourselves in the rich Indian family culture, where warmth and tradition blend seamlessly. Our honeymoon tours, ranging from 2 to 6 nights, promise a delightful journey filled with unique attractions and excursions. Explore the charm of Our Tours, and if you desire, seamlessly combine your honeymoon trip with a visit our best locations. Let our trip experts tailor your honeymoon package to reflect your desires, ensuring a truly magical and culturally rich experience. Embark on a journey of love and discovery with TravelDuti.',
    img:honeymoon,img2:honeymoon2 },
  { Holidayid: 4, Title: 'Spiritual', Head:'Witness the Best Tours With Our Travel Duti', Content: 'Embarking on a journey with TravelDuti is not merely a physical exploration; it is a transformative odyssey for the mind and spirit. In the wake of the Covid-19 pandemic, there has been a remarkable surge in the allure of spiritual travel, and our company is at the forefront of curating extraordinary experiences that resonate with this newfound global shift towards inner well-being.Discover a profound connection between mind, body, and soul as you immerse yourself in the art of meditation, yoga, and spiritual practices. At TravelDuti, we recognize travel as a highly effective method for revitalizing the senses, and our curated spiritual journeys elevate this experience to new heights.Beyond the beaten path, our spiritual travel ventures delve into the heart of sacred sites, ancient traditions, and personal growth. Uncover the hidden gems that await, where every step becomes a sacred pilgrimage, and every moment is a chance for inner reflection.From pilgrimages to tranquil retreats, our expertly crafted journeys promise a wealth of benefits, transcending the ordinary tourist experience. Join us on a voyage of self-discovery and exploration, where the destinations become more than just places on a map – they become the canvas upon which your soul paints its journey. TravelDuti invites you to embrace the extraordinary and embark on a spiritual sojourn like never before.',
    Content2:'"The journey within has emerged as a profound framework. Sacred sites, temples, ashrams, and meditation centers have evolved into potent catalysts for spiritual evolution. But what fuels this transformative shift? Spiritual travel extends a unique advantage— the chance for profound self-reflection. It provides individuals with the opportunity to disengage from the chaos, offering a serene space to contemplate thoughts and behaviors. In these sacred destinations, a transformative environment is cultivated, allowing us to authentically recognize both our strengths and weaknesses. It becomes a valuable window for personal introspection," shares Kranthi Prasad, Founder and CEO of TravelDuti.com. He goes on to unveil some extraordinary benefits of spiritual travel with HT Lifestyle. Join TravelDuti in unlocking these amazing advantages on your journey towards self-discovery and inner growth.',
    img:spiritual,img2:spiritual2 },
  { Holidayid: 3, Title: 'Family', Head:'Witness the Best Tours With Our Travel Duti', Content: 'Many affirm the significance of family, emphasizing its pivotal role in various gatherings such as family events and vacations, and more. At TravelDuti, we wholeheartedly resonate with this sentiment. Family, as a cohesive unit, holds the power to infuse joy and wonder into every aspect of life. Among the treasured moments that linger in our hearts are the photos and videos captured during family holidays – a testament to the beauty of shared experiences.These memories become extraordinary when families engage in activities like dancing or singing together, creating a unique tapestry of love and togetherness. The warmth of celebrating events, especially with children, amplifies the joy of a family holiday. While family members may pursue individual activities during vacations, this intentional separation often brings them closer. At TravelDuti, we understand that periodic family holidays, be it weekends or any other occasion, offer a golden opportunity for parents to forge deep connections with their children. Quality time spent on adventures or shared activities becomes the glue that binds families together, creating cherished moments that last a lifetime.',
    Content2:"Amidst the hustle and bustle of daily life, family members often find themselves caught up in the whirlwind of commitments, leaving little time for each other. It becomes imperative to foster a positive bond within the family, especially for your child's development. Embarking on trips and vacations together serves as a transformative means to achieve this goal.A family vacation offers a perfect opportunity to not only spend quality time together but also to fortify the bonds that tie the family unit. Engaging in outdoor activities during these moments becomes a catalyst for strengthening relationships and nurturing familial connections. Moreover, the shared experiences help alleviate stress, a common factor contributing to family problems.Scientifically speaking, incorporating regular walks into your family time has proven health benefits. Thus, as you indulge in the joy of a vacation, you are simultaneously promoting the well-being of your family. At TravelDuti, we understand the importance of these moments and strive to curate experiences that seamlessly blend family bonding, outdoor activities, and overall health and happiness.",
    img:family,img2:family2 },
  { Holidayid: 2, Title: 'Solo', Head:'Witness the Best Tours With Our Travel Duti', Content: "Traveling solo becomes a catalyst for independent decision-making, compelling you to gauge your emotions and preferences in real-time. As you journey alone, you discover intricate details about yourself—unveiling your comfort zones, desires, and the activities that truly resonate with your inner self. In truth, solo travel embodies a profound expedition of self-exploration and revelation.Contrastingly, traveling with kids or as a family often incurs higher expenses. The logistics of sharing a single gelato among multiple individuals or considering the collective expenses during holidays often escalates costs. Conversely, solo travel presents a more cost-effective option. You dictate your budget and the manner in which you allocate your resources. Whether opting for a quick snack on the go or indulging in a lavish meal, the choice is entirely yours. Moreover, planning a budget-friendly day or finding single-occupancy accommodations significantly reduces expenses. From cozy rented rooms to studio apartments, solo travel offers not just independence but also financial prudence. At TravelDuti, we understand the unique dynamics of solo travel and aim to craft experiences that empower your journey towards self-discovery and affordability.",
    Content2:"Assuming control of your decisions while traveling solo empowers you to tailor your experiences according to your whims and fancies. In contrast to group or family travel, where compromise is often the norm to cater to everyone's needs, solo travel grants you the freedom to indulge in precisely what you desire. You can dine at the restaurant of your choice, set your waking hours, or opt for a day by the pool if relaxation beckons. Beyond the immediate perks, one of the most significant advantages of solo travel lies in the opportunity for increased social interactions. Engaging with a diverse range of people becomes a natural part of the solo travel experience, fostering the potential for lasting friendships. While the prospect of striking up conversations with strangers might initially seem daunting, the reality unfolds differently. Within hours of embarking on your solo journey, you'll discover that connecting with others is more effortless than anticipated. Both fellow solo travelers and groups are often eager to engage in conversations, potentially leading to spontaneous alliances during your travels. Countless tales abound of individuals forming lifelong friendships while exploring new horizons. Who knows? Your journey could unfold a similar story for you. At TravelDuti, we celebrate the spirit of solo travel and strive to create experiences that not only empower your autonomy but also foster meaningful connections with fellow wanderers.",
    img:solo,img2:solo2 },
  {Holidayid:5, Title:'Group', Head:'Witness the Best Tours With Our Travel Duti',Content:"Embarking on a group tour with TravelDuti offers a unique chance to discover a destination alongside fellow adventurers who share your passion for exploration. It presents an excellent opportunity to witness incredible wildlife, marvel at captivating monuments, immerse yourself in fascinating cultures, and behold breathtaking scenery. Additionally, TravelDuti ensures your stay is enriched with recommended local accommodations, enhancing your overall travel experience. Whether you prefer a group tour that covers all the 'must-see sights' or veers off the beaten path, TravelDuti has you covered. Equip yourself with binoculars for an exciting wildlife tour, opt for an eco-friendly adventure with a cycling or trekking holiday. Recognizing the diverse preferences of travelers, we tailor our group tours to appeal to a variety of individuals, whether you're a seasoned visitor to India or exploring the country for the first time.",
    Content2:"Embarking on a journey with TravelDuti alongside like-minded travelers enhances the exploration of the same region. Shared interests and experiences create meaningful bonds, whether you're on a thrilling jungle safari in a National Park or indulging in the discovery of local cuisine. With TravelDuti's group tours, designed to accommodate a minimum of 8 and a maximum of 16 people, you're assured ample opportunities to forge new friendships along the way. Opting for a group tour with TravelDuti not only promises enriching experiences but also offers exceptional value for your money. Leveraging robust connections with tour operators and accommodation providers, we secure discounted rates on fabulous options, extending those savings to our travelers. Our bargaining power extends to tourist attractions and restaurants as well, ensuring you get the most out of your journey. Plus, with your tour booked, you're left free to budget for the other aspects of your trip without any hassle. Participating in a TravelDuti group tour brings a responsible and sustainable benefit by significantly reducing your carbon footprint, particularly when sharing transport options with fellow travelers. Opting for a shared vehicle minimizes emissions compared to individual driving or separate travel arrangements. It's the small choices like these that contribute to a substantial positive impact on the environment, emphasizing the commitment to responsible and sustainable travel with TravelDuti.",
    img:group,img2:group2}
];

const Holidaypackage = () => {
  const { Id} = useParams();
  console.log(Id)

  const selectedPackage = holidayPackageContent.find(
    (holidayPackage) => holidayPackage.Holidayid.toString() === Id
  );

  if (!selectedPackage) {
    return <div>Package not found</div>;
  }

  const holidaypackagebackground = {
    background: `url(${selectedPackage.img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '50vw',
  };
  const holidaycontentbackground={
    background:`url(${selectedPackage.img2})`
  }
  

  return (
    <div>
      <div className='holiday-package-main-container'>
        <div>
          <Header />
        </div>
        <div className='holiday-package-main'>
          <img src={selectedPackage.img} alt='' className='holiday-package-background'/>
          <div className='holiday-package-content-container'>
            <h1 className='holiday-head'>{selectedPackage.Title}</h1>
            <p className='holiday-para'>{selectedPackage.Head}</p>
          </div>
        </div>

        <div className='holiday-content-main-container'>
          <img src={selectedPackage.img2} alt='' className='holiday-content-background'/>
          <div className='holiday-position-container'>
            <h1 className='holiday-content-heading'>{`Discover Best ${selectedPackage.Title} Trip with Duti Trips`}</h1>
            <p className='holiday-blue-line'></p>
            <div className='holiday-content-sub-container'>
              <h1 className='holiday-content-sub-head'>{` ${selectedPackage.Title} Tours`}</h1>
              <p className='holiday-content-sub-para'>{selectedPackage.Content}</p>
              <p className='holiday-content-sub-para'>{selectedPackage.Content2}</p>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Holidaypackage;
